$height: calc(100vh - 66px);
$minheight: 150px;
$heightcard: calc((100vh - 66px)/4);

$heightcardone: 66px;
$heightcardtwo: calc(((100vh - 66px)/4) + 66px);
$heightcardthree: calc((((100vh - 66px)/4)*2) + 66px);
$heightcardfour: calc((((100vh - 66px)/4)*3) + 66px);


.scoreboard-cards {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    height: $height;
    background: url('https://goodnightstay.com/wp-content/uploads/2021/08/scottsdale.jpg');
    background-size: cover;
}

.scoreboard-cards .card {
    height: $heightcard;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    min-height: 150px;
    padding: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    -moz-transition-duration: 1s;
    -webkit-transition-duration: 1s;
    -ms-transition-duration: 1s;
    animation-duration: 1s;
}

.card-container {
    width: 100%;
    max-width: 1100px;
}

.blue-team {
    background: rgba(23, 104, 182, .8);
    // background: linear-gradient(180deg, rgba(23, 104, 182, 1) 0%, rgba(25, 118, 210, 1) 16%, rgba(25, 118, 210, 1) 84%, rgba(31, 89, 148, 1) 100%);
    color: white;
}

.red-team {
    background: rgba(211, 47, 47, .8);
    color: white;
}

.black-team {
    background: rgba(25, 25, 25, .8);
    // background: linear-gradient(180deg, rgba(25, 25, 25, 1) 0%, rgba(34, 34, 34, 1) 16%, rgba(34, 34, 34, 1) 84%, rgba(24, 24, 24, 1) 100%);
    color: white;
}

.green-team {
    background: rgba(56, 142, 60, .8);
    color: white;
}

.team-title {
    margin-block-start: 0em;
    margin-block-end: 0.5em;
    font-style: italic;

}

.number-container {
    display: flex;
    justify-content: space-evenly;

}

.sb-numbers {
    padding: 20px;
    width: 20%;
    background: rgba(255, 255, 255, 0.2);
}

.main-number {}

.sb-label {
    font-style: italic;
}

#position-1 {
    top: $heightcardone;
}

#position-2 {
    top: $heightcardtwo;
}

#position-3 {
    top: $heightcardthree;
}

#position-4 {
    top: $heightcardfour;
}

.score {
    font-weight: bold;
}

.scorebox {
    background: rgba(255, 255, 255, .4);
}

/*** Responsive Styles Large Desktop And Above ***/
@media all and (min-width: 1405px) {

    .main-number {
        font-size: 60px;
    }

    .sb-label {
        font-size: 16px;
    }

    .team-title {
        font-size: 30px;
    }
}

/*** Responsive Styles Standard Desktop Only ***/
@media all and (min-width: 1100px) and (max-width: 1405px) {
    .main-number {
        font-size: 50px;
    }

    .sb-label {
        font-size: 14px;
    }

    .team-title {
        font-size: 30px;
    }
}

@media all and (min-width: 981px) and (max-width: 1099px) {
    .main-number {
        font-size: 50px;
    }

    .sb-label {
        font-size: 14px;
    }

    .team-title {
        font-size: 30px;
    }
}

/*** Responsive Styles Tablet And Below ***/
@media all and (max-width: 980px) {}

/*** Responsive Styles Tablet Only ***/
@media all and (min-width: 768px) and (max-width: 980px) {

    .main-number {
        font-size: 40px;
    }

    .sb-label {
        font-size: 14px;
    }

    .team-title {
        font-size: 25px;
    }
}

/*** Responsive Styles Smartphone Portrait ***/
@media all and (min-width: 480px) and (max-width: 767px) {
    .main-number {
        font-size: 30px;
    }

    .sb-label {
        font-size: 12px;
    }

    .team-title {
        font-size: 20px;
    }
}

/*** Responsive Styles Smartphone Portrait ***/
@media all and (max-width: 479px) {
    .sb-numbers {
        padding: 5px;
    }

    .main-number {
        font-size: 30px;
    }

    .sb-label {
        font-size: 12px;
    }

    .team-title {
        font-size: 20px;
    }
}

/*** Responsive Styles Smartphone Only ***/
@media all and (min-height: 666px) and (max-height: 1050px) {
    .sb-numbers {
        padding: 10px;
    }

    .main-number {
        font-size: 40px;
    }
}

@media all and (max-height: 666px) {
    .scoreboard-cards {
        min-height: 600px;
    }

    .scoreboard-cards .card {
        padding: 15px;
        min-height: 150px;
    }

    .sb-numbers {
        padding: 10px;
    }

    #position-1 {
        top: 66px;
    }

    #position-2 {
        top: 216px;
    }

    #position-3 {
        top: 366px;
    }

    #position-4 {
        top: 516px;
    }

}

@media all and (max-height: 1050px) and (min-width: 980px) {
    .card-container {
        width: 80%;
    }

    .scoreboard-cards .card {
        padding: 15px 30px;
    }

    .main-number {
        font-size: 50px;
    }

    .sb-label {
        font-size: 14px;
    }

    .team-title {
        font-size: 20px;
    }

    .sb-numbers {
        padding: 10px;

    }
}