.pageHead {
    width: 100%;
    text-align: left;
}

.pageHead button {
    margin: 20px 40px;
    padding: 5px 15px;
    color: white;
    background: #930000;
}

.pageHead button:hover {
    background: #942a2a;
}

.submit-button {
    padding: 5px 15px;
    color: white !important;
    background: #930000 !important;
}

.submit-button:hover {
    background: #942a2a !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #930000 !important;
}

.text-field hr:nth-child(2) {
    border-bottom: 2px solid #930000 !important;
}

.text-field label {
    color: rgba(0, 0, 0, 0.4) !important;
}

.form-container button.Mui-disabled {
    opacity: 0.5 !important;

}