.react-pdf__Page {
    overflow-y: hidden;
    overflow-x: scroll;
    margin-top: 15px;
    padding-bottom: 113px;
}

.react-pdf__Page__canvas {

    display: inline-block !important;

}

.react-pdf__Page__textContent,
.react-pdf__Page__canvas,
.react-pdf__Page__annotations {
    // max-width: 100% !important;
}

.pdf-nav {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: rgba(0, 0, 0, 1);
    padding: 10px;
    box-sizing: border-box;
}

.pdf-nav p {
    color: #fff;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.pdf-nav i {
    border: solid #383838;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 3px;
}

.pdf-nav .right {
    margin-right: 2px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.pdf-nav .left {
    margin-left: 2px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.button-row {
    padding: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.button-row button {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 0px 5px;
    background: rgba(255, 255, 255, 0.4);
    border: none;
    outline: none;
}

.button-row button:hover {
    background: rgba(255, 255, 255, 0.7);
}

.button-row button:disabled {
    background: rgba(255, 255, 255, 0.2);
}


.zoom-icons {
    position: relative;
}

.plus::after,
.minus::after {
    content: " ";
    position: absolute;
    display: block;
    background-color: #383838;
    height: 4px;
    margin-top: -2px;
    top: 50%;
    left: 10px;
    right: 10px;
    z-index: 9;
}

.plus::before {
    content: " ";
    position: absolute;
    display: block;
    background-color: #383838;
    width: 4px;
    margin-left: -2px;
    left: 50%;
    top: 10px;
    bottom: 10px;
    z-index: 9;

}

.plus:hover::after,
.minus:hover::after,
.plus:hover::before {
    background-color: #222;
}

.react-pdf__Page__annotations,
.annotationLayer {
    display: none
}

.pdf-back-wrapper {
    text-align: left;
}

.pdf-back-button {
    font-weight: 700;
    margin: 15px;
    background: #fff;
    padding: 5px;
    width: 60px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    color: #383838;
    border: 1px solid #fff;
}

.pdf-back-button:hover {
    background: #f1f1f1;
    border: 1px solid #787878;
}

.document-list {
    width: 100%;
}

.document-item {
    text-align: left;
    padding: 30px;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #fff;
    cursor: pointer;
}

.document-item:hover {
    background: #f1f1f1;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}