.tdashboard-bg {
    background: url('https://goodnightstay.com/wp-content/uploads/2021/08/scottsdale.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: calc(100vh - 66px);
}

.tdashboard-container {
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    min-height: calc(100vh - 66px);
}

.tdashboard-container-dealership {
    padding: 20px;
}

.tdashboard-bg-cover {
    background: rgba(0, 0, 0, 0.5);
    min-height: calc(100vh - 66px);
}

.select-container {
    padding: 10px;
}

.team-name {
    color: #fff !important;
}

.tdashboard-container-dealership .MuiTablePagination-toolbar {
    display: none;
}

.tdashboard-container .MuiTableCell-root {
    padding: 8px !important;
    color: white;
    border-bottom: none;
}
.tdashboard-container .MuiTableRow-head .MuiTableCell-root {
    padding: 10px !important;
    background: rgba(0,0,0,0.2);
    font-weight:bold;
    border-bottom: none;
}
.tdashboard-container .paper-container{
    padding:0px;
   
}

.dealership-totals {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
}

.datacards-container-dealership {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    flex-wrap: nowrap;
}

.datacards-dealership {
    background: rgba(255, 255, 255, 0.9) !important;
    width: 15%;
    margin-bottom: 30px;
}

.card-title-dealership {
    height: 2.0em;
}

.dashboard-numbers-dealership {
    font-size: 60px;
}
.spinner-box{
    width:100%;
    align-items: center;
    justify-content: center;
}
.triangle-loader {
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.team-table-Blue > .paper-container{
    background:rgba(23,104,182,.95) !important;
    
}
.team-table-Green > .paper-container{
    background:rgba(56,142,60,.95) !important;
}
.team-table-Black > .paper-container{
    background:rgba(25,25,25,.95) !important;
}
.team-table-Red > .paper-container{
    background:rgba(211,47,47,.95) !important;
}