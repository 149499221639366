.menu-appbar {}

.menu-toolbar {
    background: #000;
}

.menu-container {
    width: 100%;
    padding: 15px;
}

.menu-button span {
    color: #fff;
}

.menu-right {
    float: right;
}

.menu-left {
    float: left;
}

.headerbar,
.menu-popper {
    z-index: 99;
}