.login-container {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background: url('https://goodnightstay.com/wp-content/uploads/2021/08/scottsdale.jpg');
    background-size: cover;
}

.login-box {
    padding: 40px;
    margin-top: 15vh;
    background: rgba(255, 255, 255, .9) !important;
}


.login-title {
    color: rgba(0, 0, 0, 0.7);
}

.error-message {
    background: #ff7272;
    color: #fff;
    padding: 10px;
    margin:10px;
}


.login-box .submit-button {
    padding: 5px 15px !important;
    color: white !important;
    background: #930000 !important;
    margin-top: 15px;
}

.submit-button:hover {
    background: #942a2a !important;
}


.text-field hr:nth-child(2) {
    border-bottom: 2px solid #930000 !important;
}

.reset-password-form .MuiFormControl-root {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    width: 100%;
}

.reset-password-form .MuiFormLabel-root.Mui-focused {
    color: #930000 !important;
}

.reset-password-form .MuiInput-underline:after {
    border-bottom: 2px solid #930000 !important;
}

.text-field label {
    color: rgba(0, 0, 0, 0.4) !important;
}

.back-button {
    margin-top: 20px !important;
    font-size: 12px !important;
}

.forgot-password {
    margin-top: 20px;
    font-size: 12px;
    padding: 10px;
    color: rgba(0, 0, 0, .5);
}

.forgot-password:hover {
    color: #000;
    cursor: pointer;
}

.forgot-password-wrapper {
    margin-top: 20px;
}


.password-requirements {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: left;
    padding: 20px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    margin-top: 60px;
}

.password-requirements-list {
    display: block;
}

.password-container:hover .password-requirements {
    visibility: visible;
}

.checkmark {
    color: green;
}

.redx {
    color: red;
}