.dashboard-bg {
    background: url('https://goodnightstay.com/wp-content/uploads/2021/08/scottsdale.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: calc(100vh - 66px);
}

.dashboard-container {
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    min-height: calc(100vh - 66px);
}

.main-title {
    margin-block-start: 0px;
}

.dashboard-header {
    color: #fff;
}

.paper-container {
    overflow-x: auto;
    padding: 30px;
    margin: 20px;
    background: rgba(255, 255, 255, 0.9) !important;
}

.checkboxes {
    max-width: 250px;
    justify-content: center;
    margin: 0 auto;
}

.datacards-container {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    flex-wrap: nowrap;
}

.datacards {
    background: rgba(255, 255, 255, 0.9) !important;
    width: 22%;
    margin-bottom: 30px;
}

.card-title {
    height: 2.0em;
}

.dashboard-numbers {
    font-size: 60px;
}

.tabletoolbar {
    display: flex;
    width: 100%;
}

.left-toolbar {
    width: 50%;
    text-align: left;
}

.right-toolbar {
    width: 50%;
    text-align: right;
}

.addcustomer-button {
    background: #930000 !important;
    color: #fff !important;
}

.addcustomer-button:hover {
    background: #942a2a !important;
}

.addcustomer-button:disabled {
    opacity:.5;
    background: #585858 !important;
    color: #fff !important;
}

.dashboardtablesort>.MuiSvgIcon-root {
    display: none !important;
}


.customerNameCell:hover {
    cursor: pointer !important;
}

.updateCustomerPopup {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
}

.form-container-popup {
    background: #fff;
    margin: 20vh;
    padding: 30px;
}



label[for=customerName] {
    transform: scale(.75) translate(0px, -28px) !important;
}


/*** Responsive Styles Large Desktop And Above ***/
@media all and (min-width: 1405px) {}

/*** Responsive Styles Standard Desktop Only ***/
@media all and (min-width: 1100px) and (max-width: 1405px) {}

/*** Responsive Styles Tablet And Below ***/
@media all and (max-width: 980px) {}

/*** Responsive Styles Tablet Only ***/
@media all and (min-width: 768px) and (max-width: 980px) {}

/*** Responsive Styles Smartphone Only ***/
@media all and (max-width: 767px) {

    .datacards-container {
        flex-wrap: wrap
    }

    .datacards {
        width: 47%;
        margin-bottom: 15px;
    }

    .MuiToolbar-gutters {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

/*** Responsive Styles Smartphone Portrait ***/
@media all and (max-width: 479px) {}